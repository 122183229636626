<template>
  <div id="chartid" :class="className" :style="boxStyle" :chartData="chartData" />
</template>
<script>
import echarts from "echarts";
import resize from "@/mirror-modules/resize";
import "echarts-wordcloud/dist/echarts-wordcloud";
import "echarts-wordcloud/dist/echarts-wordcloud.min";
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "bar-chart"
    },
    id: {
      type: String,
      default: "bar-chart"
    },
    boxStyle: {
      type: Object,
      default: () => {
        return { width: "400px", height: "200px" };
      }
    },
    chartData: {
      type: Array,
      default: () => {
        return [
        {name: "雨伞", value: 30},
        {name: "晴天", value: 28},
        {name: "电话", value: 24},
        {name: "手机", value: 23},
        {name: "下雨", value: 22},
        {name: "暴雨", value: 21},
        {name: "多云", value: 20},
        {name: "雨衣", value: 29},
        {name: "屋檐", value: 28},
        {name: "大风", value: 27},
        {name: "台风", value: 26},
        {name: "下雪", value: 25},
        {name: "打雷", value: 24},
        {name: "小雨", value: 30},
        {name: "中雨", value: 18},
        {name: "大雨", value: 14},
        {name: "雷阵雨", value: 13},
        {name: "下雪", value: 12},
        {name: "小雪", value: 11},
        {name: "中雪", value: 10},
        {name: "大雪", value: 9},
        {name: "暴雪", value: 8},
        {name: "东风", value: 7},
        {name: "南风", value: 6},
        {name: "西北风", value: 5},
        {name: "北风", value: 4},
        {name: "闪电", value: 3}
    ];
      }
    }
  },
  data() {
    return {
      chart: null
    };
  },

  computed: {
    styleObject: function() {
      const obj = this.boxStyle;
      for (const attr in this.boxStyle) {
        // var current = getComputedStyle(this.boxStyle)[attr]
        // 提取单位,若存在单位，得到数组。若不存在单位，得到%
        var unit = this.boxStyle[attr].match(/[a-z]+$/);
        unit = unit ? unit[0] : "%";

        obj[attr] =
          unit !== "%" ? this.boxStyle[attr] : this.boxStyle[attr] + unit;
      }
      return obj;
    }
  },
  watch: {
    chartData() {
      this.initChart();
    }
  },
  mounted() {
    this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById('chartid'));
      this.chart.on('click', (param)=> {
        var name = param.name;
        this.$emit('changeInput',name)
        });

      this.chart.setOption({
            tooltip: {
              show: true,
              position: 'top',
              textStyle: {
                  fontSize: 30
              }
            },
        series: [{
            type: "wordCloud",
            // 网格大小，各项之间间距
            gridSize: 30,
            // 形状 circle 圆，cardioid  心， diamond 菱形，
            // triangle-forward 、triangle 三角，star五角星
            shape: 'circle',
            // 字体大小范围
            sizeRange: [20, 50],
            // 文字旋转角度范围
            rotationRange: [0, 90],
            // 旋转步值
            rotationStep: 90,
            // 自定义图形
            // maskImage: maskImage,
            left: 'center',
            top: 'center',
            right: null,
            bottom: null,
            // 画布宽
            width: '90%',
            // 画布高
            height: '80%',
            // 是否渲染超出画布的文字
            drawOutOfBound: false,
            textStyle: {
                normal: {
                    color: function() {
                        return 'rgb(' + [
                            Math.round(Math.random() * 200 + 55),
                            Math.round(Math.random() * 200 + 55),
                            Math.round(Math.random() * 200 + 55)
                        ].join(',') + ')';
                    }
                },
                emphasis: {
                    shadowBlur: 10,
                    shadowColor: '#2ac'
                }
            },
            data:this.chartData
        }]
      });
    }
  }
};
</script>