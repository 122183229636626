<template>
  <a-modal :visible="visibleChildren" title="直播回放" :footer="null" @cancel="closeModal" :width="650">
    <div style="width: 600px; height: 400px">
      <!-- <video
        id="videoElement"
        class="video-js vjs-default-skin"
        style="width: calc(100% - 2px); height: calc(100% - 12px); border-radius: 10px"
        autoplay
        muted
        controls
        preload="auto"
        ref="videoElement"
      ></video> -->
      <!-- <EasyPlayer
        :videoUrl="liveUrl"
        @message="$message"
        aspect="3:5"
        :live='false'
        :fluent='false'
        autoplay='autoplay'
        stretch
        resolutiondefault='yh'
      ></EasyPlayer> -->
      <video :src="liveUrl"  autoplay style="width: calc(100% - 2px); height: calc(100% - 12px); border-radius: 10px" controls="controls"/>
    </div>
  </a-modal>
</template>
<script>
import 'flv.js/dist/flv.min.js'
import flvjs from 'flv.js'
// import EasyPlayer from '@easydarwin/easyplayer';
export default {
  props: {
    visibleChildren: {
      type: Boolean,
      required: false,
      default: false,
    },
    liveUrl: {
      type: String,
    },
  },
  // components: { EasyPlayer },
  data() {
    return {
      player: null,
    }
  },
  created() {
    console.log('create生命周期', document.getElementById('videoElement'))
  },
  mounted() {
    console.log('mounted生命周期', document.getElementById('videoElement'))
  },
  watch: {
    liveUrl: {
      handler: function (val) {
        console.log('播放链接', val)
        // setTimeout(() => {
        //   let videoElement = document.getElementById('videoElement')
        //   console.log('获取到元素没', videoElement)
        //   if (flvjs.isSupported() && this.visibleChildren) {
        //     let videoElement = document.getElementById('videoElement')
        //     console.log('获取到元素没', videoElement)
        //     this.player = flvjs.createPlayer({
        //       type: 'flv',
        //       url: val,
        //     })
        //     this.player.attachMediaElement(videoElement)
        //     this.player.load()
        //     this.player.play()
        //   }
        // }, 1000)
      },
    },
  },

  methods: {
    callbackfun(e) {
      console.log('callbackfun', e)
    },

    closeModal() {
      console.log('获取到元素没', document.getElementById('videoElement'), this.player)
      // if (this.player) {
      //   this.player.pause()
      //   this.player.unload()
      //   this.player.detachMediaElement()
      //   this.player.destroy()
      //   this.player = null
      // }

      this.$emit('closeModel')
    },
  },
}
</script>
