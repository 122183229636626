<template>
  <div style="position: relative">
    <div class="card">
      <div class="head">
        <div class="head-title">
          <span class="month_total"
            >{{
              liveRecord.publish_time
                ? liveRecord.publish_time.slice(5, 7)
                : ""
            }}月（{{ liveRecord.current_month_live_count }}场）</span
          >
          <a-date-picker
            v-model="valueDate"
            type="date"
            placeholder="直播记录"
            :clearable="false"
            @change="selectDate"
            :disabled-date="pickerOptions.disabledDate"
          ></a-date-picker>
          <div class="live_record" v-if="showLiveRecord">
            <a
              @click="showLiveRecord = false"
              style="text-align: right; display: block; font-size: 18px"
            >
              <a-icon type="close"></a-icon>
            </a>
            <div class="head_tabe">
              <span>开播时间</span>
              <span>最高观看人数</span>
              <span>直播时长</span>
            </div>
            <ul v-if="live_count.length">
              <li v-for="(item, index) in live_count" :key="index">
                <a @click="selectLive(item.room_id)">
                  <span>{{ item.publish_time }}</span>
                  <span>{{ item.statistics_max_online_user }}</span>
                  <span>{{ item.time }}</span>
                </a>
              </li>
            </ul>
            <p
              style="text-align: center; font-size: 24px; margin-top: 50px"
              v-else
            >
              无直播记录
            </p>
          </div>
        </div>
        <div class="head-tips">
          <i class="el-icon-warning-outline" style="margin-right: 12px" />
          数据更新于{{ nowDate }}｜每日更新
        </div>
      </div>
      <!-- 直播间效果记录 -->
      <div class="live">
        <div class="live-img">
          <img :src="liveRecord.cover" alt="汽车图片" />

        </div>
        <div class="live-total">
          <div class="live-total-detail">
            <div class="detail_content">
              <div class="title">
                <svg-icon
                  icon-class="calender"
                  style="color: rgb(246, 127, 87); margin-right: 8px"
                />开播日期
              </div>
              <p>
                {{
                  liveRecord.publish_time
                    ? liveRecord.publish_time.slice(0, 10)
                    : "--"
                }}
              </p>
            </div>
            <div class="detail_content">
              <div class="title">
                <svg-icon
                  icon-class="play"
                  style="color: rgb(50, 109, 230); margin-right: 8px"
                />开播时间
              </div>
              <p>
                {{
                  liveRecord.publish_time
                    ? liveRecord.publish_time.slice(11)
                    : "--"
                }}
              </p>
            </div>
            <div class="detail_content">
              <div class="title">
                <svg-icon
                  icon-class="play_stop"
                  style="color: rgb(248, 157, 101); margin-right: 8px"
                />下播时间
              </div>
              <p>
                {{
                  liveRecord.finish_time
                    ? liveRecord.finish_time.slice(11)
                    : "--"
                }}
              </p>
            </div>
            <div class="detail_content">
              <div class="title">
                <svg-icon
                  icon-class="time"
                  style="color: rgb(56, 187, 131); margin-right: 8px"
                />直播时长
              </div>
              <p>
                {{
                  this.common.timeInterval(
                    liveRecord.publish_time,
                    liveRecord.finish_time
                  )
                }}
              </p>
            </div>
            <div class="detail_content">
              <div class="title">
                <svg-icon
                  icon-class="eye-open"
                  style="color: rgb(227, 78, 58); margin-right: 8px"
                />累计观看数
              </div>
              <p>{{ liveRecord.total_user | changeNum }}</p>
            </div>
          </div>
          <div class="live-total-detail">
            <div class="detail_content">
              <div class="title">
                <svg-icon
                  icon-class="add-account"
                  style="color: rgb(86, 126, 243); margin-right: 8px"
                />单场涨粉
              </div>
              <p>{{ liveRecord.fans_increment | changeNum }}</p>
            </div>
            <div class="detail_content">
              <div class="title">
                <svg-icon
                  icon-class="monitoring"
                  style="color: rgb(58, 195, 126); margin-right: 8px"
                />平均在线人数
              </div>
              <p>{{ liveRecord.online_user_count_avg | changeNum }}</p>
            </div>
            <div class="detail_content">
              <div class="title">
                <svg-icon
                  icon-class="new_up"
                  style="color: rgb(74, 87, 243); margin-right: 8px"
                />最高在线人数
              </div>
              <p>{{ liveRecord.online_user_count_max | changeNum }}</p>
            </div>
            <div class="detail_content">
              <div class="title">
                <svg-icon
                  icon-class="favorites-fill"
                  style="color: rgb(227, 78, 58); margin-right: 8px"
                />集赞总数
              </div>
              <p>{{ liveRecord.like_count | changeNum }}</p>
            </div>
            <div class="detail_content">
              <div class="title">
                <svg-icon
                  icon-class="comments-fill"
                  style="color: rgb(86, 130, 244); margin-right: 8px"
                />评论数
              </div>
              <p>{{ liveRecord.comment_count | changeNum }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 多tab跳转 -->
    <div class="tab">
      <a @click="scrollTo(0)" :class="active === 0 ? 'active_tab' : ''">
        <span>直播数据分析</span>
      </a>
      <a @click="scrollTo(1)" :class="active === 1 ? 'active_tab' : ''">
        <span>直播评论分析</span>
      </a>
      <a
        v-if="$root.dataType == 1"
        @click="scrollTo(2)"
        :class="active === 2 ? 'active_tab' : ''"
      >
        <span>直播观众分析</span>
      </a>
      <!-- <a
        v-if="$root.dataType == 1"
        @click="scrollTo(3)"
        :class="active === 3 ? 'active_tab' : ''"
      >
        <span>直播回放</span>
      </a> -->
    </div>
    <!-- 滚动跳转提示 -->
    <div class="tab_box" v-show="scrollTips">
      <span class="tab_line"></span>
      <div class="tab_list">
        <ul class="tab_ul">
          <li class="tab_li" @click="scrollTo(0)">
            <a class="tab_li_dot">
              <i :class="active === 0 ? 'big_dot' : 'small_dot'">
                <i
                  class="el-icon-right"
                  v-if="active === 0"
                  style="line-height: 19px; margin-left: 3px; font-weight: blod"
                ></i>
              </i> </a
            ><a>直播数据分析</a>
          </li>
          <li class="tab_li" @click="scrollTo(1)">
            <a class="tab_li_dot">
              <i :class="active === 1 ? 'big_dot' : 'small_dot'">
                <i
                  class="el-icon-right"
                  v-if="active === 1"
                  style="line-height: 19px; margin-left: 3px; font-weight: blod"
                ></i>
              </i> </a
            ><a>直播评论分析</a>
          </li>
          <li v-if="$root.dataType == 1" class="tab_li" @click="scrollTo(2)">
            <a class="tab_li_dot">
              <i :class="active === 2 ? 'big_dot' : 'small_dot'">
                <i
                  class="el-icon-right"
                  v-if="active === 2"
                  style="line-height: 19px; margin-left: 3px; font-weight: blod"
                ></i>
              </i> </a
            ><a>直播观众分析</a>
          </li>
          <!-- <li class="tab_li" @click="scrollTo(3)">
            <a class="tab_li_dot">
              <i :class="active === 3 ? 'big_dot' : 'small_dot'">
                <i
                  class="el-icon-right"
                  v-if="active === 3"
                  style="line-height: 19px; margin-left: 3px; font-weight: blod"
                ></i>
              </i> </a
            ><a>直播回放</a>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="card">
      <!-- 直播数据分析 -->
      <div class="content_scroll">
        <div class="chartsTitle">统计数据</div>
        <AnalyzeLine
          :id="'brandActive'"
          :box-style="{ width: '100', height: '388px' }"
          :chart-data="analysisLine"
          v-if="analysisLine.lineData"
        />
        <div
          v-if="!analysisLine.lineData"
          style="margin: 50px 0; text-align: center; color: #ccc"
        >
          暂无数据
        </div>
      </div>
      <!-- 直播评论分析 -->
      <div class="content_scroll">
        <div class="chartsTitle">直播评论分析</div>
        <div class="chart_box">
          <div class="chart_analy">
            <!--            <MorePie :id="'morePie'" :box-style="{width:'100',height:'220px'}" :chart-data="liveComment"/>-->
            <div
              id="commentChart"
              style="width: 100%; height: 300px; margin-top: 30px"
            ></div>
          </div>
          <div class="chart_analy">
            <div class="comment">
              <svg-icon
                icon-class="comments-fill"
                style="
                  color: rgb(248, 157, 101);
                  margin-right: 9px;
                  font-size: 18px;
                "
              />评论总数
              <span>{{ liveComment.total }}</span>
            </div>
            <ul>
              <li
                class="list"
                v-for="(item, index) in liveComment.types"
                :key="item.key"
              >
                <span class="list-item">
                  <span
                    class="list_dot"
                    :style="{ opacity: 1 - index / 10 }"
                  ></span>
                  {{
                    item.type == "SERIES"
                      ? "车系"
                      : item.type == "PRICE"
                      ? "价格"
                      : item.type == "BRAND_OR_MANUFACTOR"
                      ? "品牌"
                      : item.type == "CONFIG"
                      ? "配置"
                      : item.type == "CAR_TYPE"
                      ? "车型"
                      : item.type == "CLUE"
                      ? "线索"
                      : "--"
                  }}相关
                </span>
                <span class="list-item"
                  >{{
                    item && liveComment.total > 0
                      ? common.numEdit((item.count / liveComment.total) * 100)
                      : 0
                  }}%</span
                >
                <span class="list-item">评论数</span>
                <span class="list-item">{{ item.count }}</span>
              </li>
            </ul>
          </div>
        </div>
        <!-- 车型热度 -->
        <div class="chartsTitle">车型热度 TOP5</div>
        <div class="hot">
          <div class="hot-car">
            <ul v-if="hotCarType.length > 0">
              <li v-for="(item, index) in hotCarType.slice(0, 5)" :key="index">
                <a
                  @click="selectCar(index, 2, item.series_name)"
                  :class="activeCar === index ? 'activeCar' : ''"
                >
                  <div class="message">
                    <div class="car_img">
                      <img :src="item.image" alt="汽车图片" />
                    </div>
                    <div class="car_title">{{ item.series_name }}</div>
                  </div>
                  <!-- <el-progress :percentage="item.count" :stroke-width="13" :format="format"></el-progress> -->
                  <Progress
                    :show-outside="true"
                    :active-style="activeCar === index"
                    :percentage="item.rate"
                    :text="item.count"
                  />
                </a>
              </li>
            </ul>
            <div
              v-else
              style="margin-top: 50px; text-align: center; color: #ccc"
            >
              暂无数据
            </div>
          </div>
          <div class="hot-comment">
            <div class="search_box">
              <a-input-search
                placeholder="请输入内容"
                v-model="commentWord"
                class="input-with-select"
              >
                <!-- <el-button class="btn_search" slot="append" type="primary" @click="selectComment(1,commentWord)">搜索</el-button> -->
                <a
                  class="btn_search"
                  slot="enterButton"
                  @click="selectComment(1, commentWord)"
                  >搜索</a
                >
              </a-input-search>
            </div>
            <div class="hot-comment-title">
              <div class="list_title">
                评论列表（{{ commentList.pagination.total | changeNum }}）
              </div>
              <div class="list_sort">
                <a @click="selectComment(0, '')">所有</a>
              </div>
            </div>
            <div
              class="infinite-list-wrapper"
              v-infinite-scroll="loadMore"
              infinite-scroll-disabled="busy"
              infinite-scroll-distance="10"
            >
              <ul
                class="comment_list"
                v-if="commentList && commentList.list.length > 0"
              >
                <li
                  v-for="(item, index) in commentList.list"
                  :key="index"
                  class="comment_box"
                >
                  <a-avatar :src="item.avatar" />
                  <div class="comment_content">
                    <p>{{ item.user_name }}</p>
                    <div class="content">{{ item.content }}</div>
                    <p>{{ item.publish_time }}</p>
                  </div>
                </li>
                <p style="height: 30px">
                  <span v-if="loading">加载中...</span
                  ><span v-if="noMore" style="height: 30px">没有更多了</span>
                </p>
              </ul>
              <div v-else style="height: 453px">
                <div
                  style="
                    height: 453px;
                    line-height: 453px;
                    text-align: center;
                    color: #ccc;
                  "
                  v-if="!loading"
                >
                  暂无数据
                </div>
                <!--                <div -->
                <!--                  v-else -->
                <!--                  v-loading='loading' -->
                <!--                  style="height:453px"     -->
                <!--                  element-loading-text="拼命加载中" -->
                <!--                  element-loading-spinner="el-icon-loading"></div>-->
              </div>
            </div>
            <WordCloud
              :id="'WordCloud'"
              :box-style="{ width: '100', height: '220px' }"
              :chart-data="hotWord"
              @changeInput="changeInput"
            />
          </div>
        </div>
      </div>
      <!-- 直播观众分析 -->
      <div class="content_scroll" v-if="$root.dataType == 1">
        <div class="chartsTitle">直播观众分析</div>
        <div class="audience">
          <ul class="audience-address" v-if="audienceAdress.length > 0">
            <li v-for="(item, index) in audienceAdress" :key="index">
              <div class="province">
                <span>{{ item.province }}</span>
                <span>{{ item.tips }}%</span>
              </div>
              <Progress
                :stroke-width="8"
                :show-text="false"
                :percentage="item.rate"
                :rateBgd="
                  index > 2 ? (index > 5 ? '#73DD95' : '#6E79F5') : '#FC9F66'
                "
              />
              <!-- <el-progress

                :percentage="50"
                :show-text="false"
                :stroke-width="8"
                :color="index>2?(index>5?'#73DD95':'#6E79F5'):'#FC9F66'"
              ></el-progress> -->
            </li>
          </ul>
          <div
            v-else
            style="margin-top: 50px; text-align: center; color: #ccc; flex: 1"
          >
            暂无数据
          </div>
          <div class="audience-chart">
            <div
              v-if="!fansStat"
              style="margin-top: 50px; text-align: center; color: #ccc"
            >
              暂无数据
            </div>
            <PieChart
              :id="'fansGenderChart'"
              :width="'100%'"
              :height="'340px'"
              :pie-data="fansStat"
              :show-title="false"
              v-else
            />
          </div>
        </div>
      </div>
      <!-- 直播回放列表 -->
      <!-- <div class="content_scroll" >
        <div class="chartsTitle">直播回放</div>
        <a-table :columns="columns" :data-source="tableData" :pagination="false" :scroll="{ y: 240 }">
          <span slot="standard" slot-scope="text, record">
            <a-button type='link' @click='openVideo(record.url)'>查看回放</a-button>
          </span>
        </a-table>
        
        <live-watch :visible-children="showLiveWatch" :live-url="palyUrl" @closeModel="close" />
      </div> -->
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
import AnalyzeLine from "./AnalyzeLine";
import MorePie from "./pie/MorePie";
import PieChart from "./pie";
import WordCloud from "./WordCloud";
import moment from "moment";
import Progress from "./Progress";
import raf from "raf";
import { createNamespacedHelpers } from "vuex";
import api from '@/api/proxy/LiveAnalyze.js'
import liveWatch from './pie/liveWatch.vue'
const { mapState, mapMutations, mapActions } =
  createNamespacedHelpers("live_analyze");
const columns = [
  {
    dataIndex: 'room_id',
    title: '直播间ID',
    width:250
  },
  {
    title: '回放链接',
    dataIndex: 'url',
  },
  {
    title: '操作',
    dataIndex: 'Standard',
    scopedSlots: { customRender: 'standard' },
    width:250
  },
];


export default {
  components: {
    AnalyzeLine,
    MorePie,
    PieChart,
    WordCloud,
    Progress,
    liveWatch
  },
  props: {
    roomId: {
      type: String,
      required: true,
      default: undefined,
    },
  },
  data() {
    return {
      nowDate: "",
      valueDate: "",
      commentWord: "",
      active: 0,
      scrollTips: false,
      activeCar: "",
      showLiveRecord: false,
      pickerOptions: {
        disabledDate: (current) => {
          return current && current > moment().endOf("day");
        },
      },
      scrollTime: 500,
      videoList:[],
      tableData:[],
      columns,
      showLiveWatch:false,
      palyUrl:''
    };
  },
  watch: {
    liveComment(val) {
      this.initChart(val);
    },
  },
  computed: {
    ...mapState({
      liveRecord: (state) => state.liveRecord,
      liveComment: (state) => state.liveComment,
      commentList: (state) => state.commentList,
      hotCarType: (state) => state.hotCarType,
      analysisLine: (state) => state.analysisLine,
      // commentTotal: state => state.commentTotal,
      current: (state) => state.current,
      loading: (state) => state.loading,
      disabledLoad: (state) => state.loading || state.noMore,
      noMore: (state) => state.noMore,
      hotWord: (state) => state.hotWord,
      fansStat: (state) => state.fansStat,
      audienceAdress: (state) => state.audienceAdress,
      live_count: (state) => state.live_count,
    }),
  },
  created() {
    this.changeState({ name: "room_id", data: this.roomId });
  },
  mounted() {
    this.getInitialData(); // 初始请求接口
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll, false);
    this.nowDate = moment(new Date()).add("year", 0).format("YYYY-MM-DD HH:mm");
    // this.getLiveVideoList()
  },
  methods: {
    ...mapActions([
      "getLiveRecordAnaly",
      "getCommentAnalysis",
      "getCommentHot",
      "getCommentList",
      "getAudience",
      "getAnalysisChart",
      "getHotWord",
      "getAudienceAdress",
      "getLiveCalender",
    ]),
    ...mapMutations(["changeState"]),
    format(percentage) {
      return percentage;
    },
    getInitialData() {
      this.getLiveRecordAnaly();
      this.getCommentAnalysis();
      this.getCommentHot();
      this.getCommentList();
      this.getAudience();
      this.getAnalysisChart();
      this.getHotWord();
      this.getAudienceAdress();
    },
    selectDate(date) {
      if (!date) return;
      this.showLiveRecord = true;
      let d = new Date();
      let beginTime = `${moment(date).format("YYYY-MM-DD")} 00:00:00`;
      this.changeState({ name: "begin_time", data: beginTime });
      let time1 = moment(d).valueOf() - 1000 * 24 * 60 * 60;
      let time2 = moment(date).valueOf();
      let value = "";
      if (time1 > time2) {
        value = `${moment(date).format("YYYY-MM-DD")} 23:59:59`;
        this.changeState({ name: "end_time", data: value });
      } else {
        value = moment(d).format("YYYY-MM-DD HH:mm:ss");
      }
      this.changeState({ name: "end_time", data: value });
      this.changeState({
        name: "author_id",
        data: this.$route.query.author_id,
      });
      this.getLiveCalender();
    },
    // 获取直播回放
    getLiveVideoList(){
  
      api.getLiveVideo(this.$route.params.roomId).then(res=>{
        if(res.data.code==0){
          this.tableData =res.data.data
        }
      }).catch(res=>{
        this.$message.error(res.data.msg)
      })
    },
    // 切换直播场次
    selectLive(index) {
      (this.showLiveRecord = false), this.clearComment();
      this.changeState({ data: index, name: "room_id" });
      this.getInitialData();
      this.nowDate = moment(new Date())
        .add("year", 0)
        .format("YYYY-MM-DD HH:mm");
    },
    selectComment(count, word) {
      if (count == 1) {
        this.commentWord = word;
        this.activeCar = "";
        this.changeState({ name: "jubgeComment", data: true });
      } else if (count == 2) {
        this.commentWord = word;
        this.changeState({ name: "jubgeComment", data: false });
      } else {
        this.activeCar = "";
        this.commentWord = "";
        this.changeState({ name: "jubgeComment", data: true });
      }
      this.clearComment();
      this.changeState({ name: "word", data: this.commentWord });
      this.getCommentList();
    },
    selectCar(index, count, word) {
      this.clearComment();
      this.activeCar = index;
      this.selectComment(count, word);
    },
    changeInput(value) {
      this.clearComment();
      this.selectComment(1, value);
    },
    clearComment() {
      this.changeState({ name: "current", data: 1 });
      this.changeState({ name: "noMore", data: false });
      this.changeState({ name: "loading", data: false });
      this.changeState({
        name: "commentList",
        data: {
          list: [],
          pagination: {},
        },
      });
    },
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".content_scroll");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      let container = window;
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      navContents.forEach((item) => {
        let eleOffsetTop = this.getOffsetTop(item, container);
        let targetScrollTop = scrollTop + eleOffsetTop;
        offsetTopArr.push(targetScrollTop);
      });
      let navIndex = 0;
      if (scrollTop > 360) {
        this.scrollTips = true;
      } else {
        this.scrollTips = false;
      }
      // let slTop = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
        // 那么此时导航索引就应该是 n 了
        // slTop += offsetTopArr[n];
        if (scrollTop > offsetTopArr[n]) {
          navIndex = n + 1;
        }
      }
      this.active = navIndex;
    },
    scrollTo(item) {
      // this.active = item;
      let dom = document.querySelectorAll(".content_scroll")[item];
      let container = window;
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      let eleOffsetTop = this.getOffsetTop(dom, container);
      let targetScrollTop = scrollTop + eleOffsetTop;
      const startTime = Date.now();
      const frameFunc = () => {
        const timestamp = Date.now();
        const time = timestamp - startTime;
        const nextScrollTop = this.easeInOutCubic(
          time,
          scrollTop,
          targetScrollTop,
          this.scrollTime
        );
        if (container === window) {
          window.scrollTo(window.pageXOffset, nextScrollTop);
        } else {
          container.scrollTop = nextScrollTop;
        }
        if (time < this.scrollTime) {
          raf(frameFunc);
        } else {
          this.isLockScroll = false;
        }
      };
      this.isLockScroll = true;
      raf(frameFunc);
    },
    easeInOutCubic(t, b, c, d = 500) {
      const cc = c - b;
      t /= d / 2;
      if (t < 1) {
        return (cc / 2) * t * t * t + b;
      }
      return (cc / 2) * ((t -= 2) * t * t + 2) + b;
    },
    /**
     * @desc: 获取目标和容器之间的高度差，该方法会漏算容器的border-top
     * @param {type}
     * @return: {type}
     * @example:
     */
    getOffsetTop(element, container) {
      if (!element) {
        return 0;
      }
      if (!element.getClientRects().length) {
        return 0;
      }
      const rect = element.getBoundingClientRect();
      if (rect.width || rect.height) {
        if (container === window) {
          container = element || document;
          return rect.top - container.clientTop;
        }
        return rect.top - container.getBoundingClientRect().top;
      }
      return rect.top;
    },
    // 滚动加载评论
    loadMore() {
      console.log("loadMore");
      if (this.current <= this.commentList.pagination.pages) {
        this.getCommentList();
      }
    },
    initChart(val) {
      this.chart = echarts.init(document.getElementById("commentChart"));
      let clue = val.types.filter((e) => {
        return e.type == "CLUE";
      });
      let clue_count = clue.length > 0 ? clue[0].count : 0;
      let lineargroup = [
        {
          value: val.total,
          name: "所有评论",
          oriname: "所有评论",
          number: val.total,
        },
        {
          value: val.validate_comment_count,
          name: "有效评论",
          oriname: "有效评论",
          number: val.validate_comment_count,
        },
        {
          value: clue_count,
          name: "线索",
          oriname: "线索",
          number: clue_count,
        },
      ];
      let data = [];
      for (let i = 0; i < lineargroup.length; i++) {
        let obj = {
          value: lineargroup[i].value || 0,
          num: lineargroup[i].number || 0,
          name: lineargroup[i].oriname,
        };
        data.push(obj);
      }
      this.chart.setOption({
        backgroundColor: "",
        color: ["#63cf8f", "#6c8ff7", "#f7c866"],
        grid: {
          top: "-5",
          left: "2%",
          right: 20,
          height: "20",
          bottom: "0",
        },
        xAxis: {
          show: false,
        },
        yAxis: [
          {
            show: false,
            boundaryGap: false,
            type: "category",
          },
        ],
        series: [
          {
            top: 0,
            type: "funnel",
            height: "300",
            gap: 15,
            minSize: 100,
            left: "5%",
            width: "80%",
            borderWidth: 0,
            borderType: "dashed",
            label: {
              show: true,
              position: "inside",
              fontSize: "13",
              formatter: function (d) {
                var ins = d.name + "  " + d.data.num;
                return ins;
              },
              rich: {
                aa: {
                  padding: [8, 0, 6, 0],
                },
              },
            },
            data: data,
          },
        ],
      });
    },
    // 打开直播回放
    openVideo(url) {
        this.showLiveWatch = true
        this.palyUrl = url
    },
    close() {
      this.showLiveWatch = false
      this.palyUrl = ''
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    this.changeState({ name: "jubgeComment", data: true });
    this.clearComment();
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
@mixin hideSCroll {
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
ul {
  list-style: none;
}
.tab {
  display: flex;
  font-size: 16px;
  a {
    padding: 0 32px;
    height: 49px;
    line-height: 49px;
    background: #fafafa;
    border-radius: 8px 8px 0 0;
    margin-right: 12px;
    color: #8f94a2;
  }
  .active_tab {
    background-color: #fff;
    color: #526ff3;
    span {
      display: inline-block;
      height: 47px;
      border-bottom: 2px solid #526ff3;
      font-weight: bold;
    }
  }
}

.live_record {
  width: 330px;
  height: 164px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 5px;
  position: absolute;
  top: 89px;
  left: 85px;
  background-color: #fff;
  font-size: 14px;
  color: #8f94a2;
  .head_tabe {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    height: 24px;
    line-height: 24px;
  }
  ul {
    height: 108px;
    overflow-y: auto;
    @include hideSCroll();
    li {
      > :hover {
        color: #129cf7 !important;
        border-bottom: 1px solid #129cf7 !important;
      }
      a {
        display: flex;
        height: 24px;
        line-height: 24px;
        border-bottom: 1px solid #ddd;
        span {
          flex: 1;
        }
        :nth-child(2) {
          text-align: center;
        }
        :nth-child(3) {
          text-align: right;
        }
      }
    }
  }
}
.tab_box {
  width: 163px;
  height: 272px;
  position: fixed;
  right: 40px;
  bottom: 100px;
  z-index: 9999;
  .tab_line {
    display: inline-block;
    width: 1px;
    height: 217.5px;
    position: absolute;
    left: 27px;
    background: #fff;
    top: 50%;
    margin-top: -109px;
    z-index: 1;
  }

  .tab_list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    background: rgba($color: #17233d, $alpha: 0.48);

    .tab_ul {
      width: 128px;
      height: 100%;
      padding: 36px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-around;

      .tab_li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #fff;

        .tab_li_dot {
          display: flex;
          width: 19px;
          height: 19px;
          justify-content: center;
          align-items: center;
          z-index: 2;
          .small_dot {
            display: flex;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #fff;
          }
          .big_dot {
            display: flex;
            width: 100%;
            height: 100%;
            display: flex;
            border-radius: 50%;
            background: #4a58f3;
          }
        }
      }
    }
  }
}
.card {
  padding: 20px;
  margin-bottom: 16px;
  background-color: #fff;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    &-title {
      .month_total {
        font-size: 16px;
        color: #17233d;
      }
    }
    &-tips {
      text-align: right;
      padding-right: 56px;
      font-size: 14px;
      color: #8f94a2;
      .get_new_date {
        color: #4a58f3;
        font-size: 20px;
        vertical-align: center;
      }
      .active {
        -webkit-animation: animal 1s infinite linear;
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
      }
    }
  }
  .content_scroll {
    width: calc(100% - 40px);
  }
  .live {
    display: flex;
    padding: 36px 0 0 20px;
    width: 100%;
    &-img {
      width: 232px;
      height: 174px;
      margin-right: 76px;
      img {
        height: 100%;
        width: 100%;
        border: 1px solid rgba(151, 151, 151, 0.3);
        border-radius: 4px;
      }
    }
    &-total {
      width: 70%;
      &-detail {
        display: flex;
        flex-grow: row nowrap;
        // align-items: center;
        // justify-content: space-around;
        width: 100%;
        .detail_content {
          flex: 1;
          font-size: 16px;
          color: #8f94a2;
          text-align: center;
          p {
            font-size: 24px;
            color: #17233d;
            padding: 24px 0 48px 0;
            font-weight: bold;
          }
        }
      }
    }
  }
  .chartsTitle {
    border-left: 4px solid #4a58f3;
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0 20px 44px;
    padding-left: 10px;
  }
  .chart_box {
    display: flex;
    margin-left: 70px;
    .chart_analy {
      flex: 1;
      .comment {
        font-size: 14px;
        color: #8f94a2;
        padding-bottom: 10px;
        span {
          font-size: 20px;
          color: #0b1100;
        }
      }
      .list {
        display: flex;
        // justify-content: space-around;
        align-items: center;
        height: 59px;
        border-top: 1px solid #f1f2f5;
        font-size: 14px;
        color: #17233d;
        &:last-child {
          border-bottom: 1px solid #f1f2f5;
        }
        &-item {
          flex: 1;
          color: #17233d;
          .list_dot {
            width: 10px;
            height: 10px;
            border-radius: 4px;
            background: #4a58f3;
            display: inline-block;
            margin-right: 16px;
          }
        }
      }
    }
  }

  .hot {
    display: flex;
    margin-left: 70px;
    &-car {
      flex: 1;
      .activeCar {
        .car_title {
          color: #fbb76b;
        }
        .el-progress-bar__inner {
          background-color: #fbb76b;
        }
      }
      ul {
        width: 80%;
        padding-left: 30px;
        li {
          padding-bottom: 37px;
          .el-progress-bar__inner {
            background-color: #4a58f3;
          }
          .message {
            display: flex;
            align-items: center;
            padding-bottom: 16px;
            .car_img {
              width: 120px;
              height: 86px;
              margin-right: 12px;
              img {
                height: 100%;
                width: 100%;
                border-radius: 5px;
              }
            }
            .message {
              flex: 1;
              text-align: justify;
            }
          }
        }
      }
    }
    &-comment {
      flex: 1;
      .search_box {
        margin-bottom: 20px;
        display: flex;
      }
      .el-input-group__append {
        padding: 0 !important;
        border: 0 !important;
        .btn_search {
          display: inline-block;
          width: 92px;
          background: #494fff;
          font-weight: 600;
          color: #ffffff;
          line-height: 38px;
          text-align: center;
          border: 1px solid #4954fc;
          border-radius: 0px 4px 4px 0px;
        }
      }

      &-title {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        .list_title {
          border-left: 4px solid #4a58f3;
          font-size: 16px;
          padding-left: 10px;
        }
        .list_sort {
          font-size: 14px;
          color: #4a58f3;
          a {
            padding: 0 5px;
          }
        }
      }
      .infinite-list-wrapper {
        height: 453px;
        overflow-y: auto;
      }
      .comment_list {
        display: block;
        border-bottom: 2px solid #f1f2f5;
        // @include hideSCroll();
        .comment_box {
          display: flex;
          .comment_content {
            display: flex;
            flex-flow: column nowrap;
            padding: 0 0 16px 12px;
            font-size: 14px;
            color: #8f94a2;
            p {
              height: 24px;
              line-height: 24px;
              padding-bottom: 4px;
            }
            .content {
              font-size: 12px;
              color: #17233d;
              padding-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .audience {
    display: flex;
    margin-left: 70px;
    padding-bottom: 64px;
    &-chart {
      flex: 1;
    }
    &-address {
      flex: 1;
      height: 320px;
      overflow-y: auto;
      padding-left: 30px;
      @include hideSCroll();
      width: 80%;
      li {
        width: 80%;
        font-size: 12px;
        color: #8f94a2;
        padding-bottom: 16px;
        .province {
          display: flex;
          justify-content: space-between;
          padding-bottom: 4px;
        }
      }
    }
  }
}
</style>
